import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../img/icons/fontawesome'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import { Container, Row, Col, Heading, Text, Image } from '../components/UI'
import Logo from '../img/logo.svg'
import ServiceDetails from '../components/ServiceDetails';
import CourseGoals from '../components/CourseGoals';

export const ServicesPageTemplate = ({
  hero,
  goals,
  description,
  services
}) => (
  <>
    <Helmet>
      <title>Services - SecSafeConsult</title>
      <meta name="description" content="Detaljer omkring kurser, rådgivning mm. jeg kan tilbyde din virksomhed." />
      <meta property="og:title" content="Services - SecSafeConsult" />
      <meta property="og:url" content="/services" />
    </Helmet>
    {/* Hero */}
    <Container maxWidth="content" mx="auto">
      <Container textAlign="center" mt={["0", "xxxl"]}>
        <Heading highlight="center">{hero.title}</Heading>
        <Text fontSize="xl">{hero.subtitle}</Text>
        <Container px={["xl", "subsection"]}>
          <Image src={!!hero.heroimage.image.childImageSharp ? hero.heroimage.image.childImageSharp.fluid.src : hero.heroimage.image} alt={hero.heroimage.alt} width="100%" height="auto" />
          <Container opacity=".9">
            <Image src={Logo} width="10%" height="auto" position="absolute" alt="SecSafeConsult logo" right="-2vw" bottom="-2vh" />
          </Container>
        </Container>
      </Container>
    </Container>

    {/* CourseGoals */}
    <CourseGoals goals={goals} />

    {/* CourseDescription */}
    <Container backgroundColor="bg.1" mt="subsection">
      <Container py="subsection" maxWidth="content" mx="auto">
        <Row>
          <Col md={6} p="xl">
            <Image src={!!description.descriptionimage.image.childImageSharp ? description.descriptionimage.image.childImageSharp.fluid.src : description.descriptionimage.image} alt={description.descriptionimage.alt} height="auto" width="100%" />
          </Col>
          <Col md={6} p="xl">
            <Heading type="h2" highlight>{description.heading}</Heading>
            <Text>{description.text}</Text>
          </Col>
        </Row>
      </Container>
    </Container>

    {/* ServiceDetails */}
    <ServiceDetails services={services.courses} />
    <ServiceDetails services={services.others} />
  </>
)

ServicesPageTemplate.propTypes = {
  hero: PropTypes.object,
  goals: PropTypes.array,
  description: PropTypes.object,
  services: PropTypes.object
}

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ServicesPageTemplate
        hero={frontmatter.hero}
        goals={frontmatter.goals}
        description={frontmatter.description}
        services={frontmatter.services}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicesPage

export const servicesPageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        hero {
          title
          subtitle
          heroimage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            } 
          }
        }
        goals {
          heading
          text
        }
        description {
          heading
          text
          descriptionimage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        services {
          courses {
            heading
            subheading
            blurbs {
              heading
              text
              points {
                point
              }
              price
            }
          }
          others {
            heading
            subheading
            blurbs {
              heading
              text
              price
            }
          }
        }
      }
    }
  }
`
