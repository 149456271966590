import React from 'react';
import { Container, Row, Col, Text, Card, CardBody, Heading, RouterButton } from './UI';

function ServiceDetailBlurbs(props) {
  const { services } = props;

  return (
    <Container maxWidth="content" textAlign="center" mx="auto" my="subsection">
      <Heading type="h2" px={["xl", "0"]}>{services.heading}</Heading>
      <Heading type="h3" color="text.0" px={["xl", "0"]}>{services.subheading}</Heading>
      <Heading type="h5" color="text.0" px={["xl", "0"]}>Priser er eksl. moms</Heading>
      
      <Container mx={services.blurbs.length === 1 ? ["0", "xxxxl", "subsection", "section"] : ["0", "0", "0", "xxxxl"]} my="xxl">
        <Row>
          {services.blurbs && services.blurbs.map((item, index) => (
            <Col md={services.blurbs.length === 2 ? 6 : 4} p="xl" key={index}>
              <Card p="xl" backgroundColor="bg.1">
                <Heading type="h4" highlight="center">{item.heading}</Heading>
                <CardBody p="0" textAlign="left">
                  <Text>{item.text}</Text>
                  <ul>
                    {item.points && item.points.map((item, index) => (
                      <li key={index}>{item.point}</li>
                    ))}
                  </ul>
                  <Text fontWeight="bold" fontSize="xl" textAlign="center">{item.price}</Text>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <RouterButton to="/contact" m="0" p="0" px="lg" py="md">Hør mere</RouterButton>
    </Container>
  );
}

export default ServiceDetailBlurbs;