import React from 'react';
import { Container, Row, Col, Icon, Text, Heading } from './UI';

function CourseGoals(props) {
  const { goals } = props;

  function getIcon(index) {
    const x = index % 3;

    switch (x) {
      case 0:
      default:
        return "exclamation-triangle";

      case 1:
        return "house-damage";

      case 2:
        return "balance-scale-left";
    }
  }

  return (
    <Container maxWidth="content" mx="auto" mt="subsection">
      <Row>
        {goals && goals.map((item, index) => (
          <Col sm={12 / goals.length} p="xl" key={index}>
            <Icon icon={getIcon(index)} size="2x" />
            <Heading type="h3" mt="md">{item.heading}</Heading>
            <Text>{item.text}</Text>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CourseGoals;